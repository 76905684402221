(function () {
  'use strict';

  /* @ngdoc object
   * @name home.matches
   * @description
   *
   */
  angular
    .module('neo.public.resultats', [
      'ui.router'
    ]);
}());
/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


